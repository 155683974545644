import dynamic from 'next/dynamic'
const CampaignSignUp = dynamic(() => import('@/components/campaign/campaign-sign-up'))
const CategorySelector = dynamic(() => import('@/components/category/category-selector'))
const ContactUs = dynamic(() => import('@/components/contact-us/contact-us'))
const ContentUp = dynamic(() => import('@/components/content/content-up'))
const CustomerService = dynamic(() => import('@/components/customer-service/customer-service'))
const ContentBlock = dynamic(() => import('@/components/article/content-block'))
const ContentHeadline = dynamic(() => import('@/components/article/content-headline'))
const ContentLrImageText = dynamic(() => import('@/components/content/content-lr-image-text'))
const FitGuide = dynamic(() => import('@/components/size-guide/fit-guide'))
const Hero = dynamic(() => import('@/components/hero/hero'))
const HtmlContent = dynamic(() => import('@/components/html-content/html-content'))
const ImageGalleryUp = dynamic(() => import('@/components/gallery/image-gallery-up'))
const NewsletterSignUp = dynamic(() => import('@/components/newsletter/newsletter-signup'))
const PageHeader = dynamic(() => import('@/components/common/page-header'))
const ProductUp = dynamic(() => import('@/components/product/product-up'))
const Product3Up = dynamic(() => import('@/components/product/product-3up'))
const Promotion1Up = dynamic(() => import('@/components/promotion/promotion-1up'))
const Promotion1UpVideo = dynamic(() => import('@/components/promotion/promotion-1up-video'))
const ProductLifestyleImages = dynamic(
  () => import('@/components/product/product-lifestyle-images')
)
const Secondary2UpHero = dynamic(() => import('@/components/secondary-2up/secondary-2up-hero'))
const Product4UpSliderArray = dynamic(() => import('@/components/product/product-4up-slider-array'))
const ProductSeasonalSliders = dynamic(
  () => import('@/components/product/product-seasonal-sliders')
)
const RelatedSearches = dynamic(() => import('@/components/related-searches/related-searches'))
const SizingBlock = dynamic(() => import('@/components/size-guide/sizing-block'))
const SizingMenuRedesign = dynamic(() => import('@/components/size-redesign/sizing-menu'))
const MeasureGuideRedesign = dynamic(() => import('@/components/size-redesign/measure-guide'))
const SizingBlockRedesign = dynamic(() => import('@/components/size-redesign/sizing-block'))
const FitGuides = dynamic(() => import('@/components/size-redesign/fit-guide'))
const SizeGuidTitle = dynamic(() => import('@/components/size-redesign/size-guide-title'))
const ErrorBoundary = dynamic(() => import('@/components/common/error-boundary'))
const Content5050 = dynamic(() => import('@/components/content/content-5050'))
const ContentSideBySideCard = dynamic(
  () => import('@/components/content/content-side-by-side-card')
)
const Content7030 = dynamic(() => import('@/components/content/content-7030'))
const Headline = dynamic(() => import('@/components/content/content-headline'))
const ProductPromotion = dynamic(() => import('@/components/promotion/product-promotion'))
const ModuleSlider = dynamic(() => import('@/components/module-slider/ModuleSlider'))
const ProductBundle = dynamic(() => import('@/components/product-bundle/product-bundle'))

const getModule = (moduleName, props, index, positionIndex, modulesLength) => {
  const moduleProps = {
    ...props,
    positionIndex,
    modulesLength,
    order: positionIndex + 1
  }

  const modules = {
    campaignSignUp: <CampaignSignUp {...moduleProps} key={`${moduleName}-${index}`} />,
    categorySelector: <CategorySelector {...moduleProps} key={`${moduleName}-${index}`} />,
    contactUs: <ContactUs {...moduleProps} key={`${moduleName}-${index}`} />,
    contentUp: <ContentUp {...moduleProps} key={`${moduleName}-${index}`} />,
    contentBlock: <ContentBlock {...moduleProps} key={`${moduleName}-${index}`} />,
    contentHeadline: <ContentHeadline {...moduleProps} key={`${moduleName}-${index}`} />,
    contentLrImageText: <ContentLrImageText {...moduleProps} key={`${moduleName}-${index}`} />,
    customerService: <CustomerService {...moduleProps} key={`${moduleName}-${index}`} />,
    fitGuide: <FitGuide {...moduleProps} key={`${moduleName}-${index}`} />,
    hero: <Hero {...moduleProps} key={`${moduleName}-${index}`} />,
    htmlContent: <HtmlContent {...moduleProps} key={`${moduleName}-${index}`} />,
    imageGalleryUp: <ImageGalleryUp {...moduleProps} key={`${moduleName}-${index}`} />,
    lifestyleImages: <ProductLifestyleImages {...moduleProps} key={`${moduleName}-${index}`} />,
    newsletterSignUp: <NewsletterSignUp {...moduleProps} key={`${moduleName}-${index}`} />,
    pageHeader: <PageHeader {...moduleProps} key={`${moduleName}-${index}`} />,
    productUp: <ProductUp {...moduleProps} key={`${moduleName}-${index}`} />,
    product3Up: <Product3Up {...moduleProps} key={`${moduleName}-${index}`} />,
    promotion1Up: <Promotion1Up {...moduleProps} key={`${moduleName}-${index}`} />,
    promotion1UpVideo: <Promotion1UpVideo {...moduleProps} key={`${moduleName}-${index}`} />,
    secondary2UpHero: <Secondary2UpHero {...moduleProps} key={`${moduleName}-${index}`} />,
    product4UpArray: (
      <Product4UpSliderArray product4UpData={moduleProps} key={`${moduleName}-${index}`} />
    ),
    product4Up: <ProductSeasonalSliders {...moduleProps} key={`${moduleName}-${index}`} />,
    relatedSearches: <RelatedSearches {...moduleProps} key={`${moduleName}-${index}`} />,
    sizingBlock: <SizingBlock {...moduleProps} key={`${moduleName}-${index}`} />,
    sizingMenu: <SizingMenuRedesign {...moduleProps} key={`${moduleName}-${index}`} />,
    sizeGuideBlock: <SizingBlockRedesign {...moduleProps} key={`${moduleName}-${index}`} />,
    measureGuide: <MeasureGuideRedesign {...moduleProps} key={`${moduleName}-${index}`} />,
    fitGuides: <FitGuides {...moduleProps} key={`${moduleName}-${index}`} />,
    sizeGuideEyebrow: <SizeGuidTitle {...moduleProps} key={`${moduleName}-${index}`} />,
    5050: <Content5050 {...moduleProps} key={`${moduleName}-${index}`} />,
    sideBySideCard: <ContentSideBySideCard {...moduleProps} key={`${moduleName}-${index}`} />,
    7030: <Content7030 {...moduleProps} key={`${moduleName}-${index}`} />,
    headline: <Headline {...moduleProps} key={`${moduleName}-${index}`} />,
    productPromotion: <ProductPromotion {...moduleProps} key={`${moduleName}-${index}`} />,
    sliders: <ModuleSlider {...props} key={`${moduleName}-${index}`} />,
    productBundle: <ProductBundle  {...moduleProps} key={`${moduleName}-${index}`} />
  }

  return modules[moduleName]
}

const Modules = ({ modules }) => {
  if (typeof modules == 'undefined') {
    return <div>No modules</div>
  }

  return (
    <>
      {modules.length > 0 &&
        modules.map((module, index) => {
          let moduleKey = module?.data?.sys?.id || Math.random()
          return (
            <ErrorBoundary fallbackComponent={<></>} key={`${index}-${moduleKey}`}>
              {getModule(module.name, module.data, `${index}-${moduleKey}`, index, modules?.length)}
            </ErrorBoundary>
          )
        })}
    </>
  )
}

export default Modules
